import {
  AnchorRouter,
  Button,
  ContributionLogo,
  EmergencySavingsLogo,
  FynbosLogo,
  Icon,
  Router,
  TFSALogo
} from '@/Components'
import { cn } from '@/Lib'
import * as Separator from '@radix-ui/react-separator'
import { FC, ReactNode, useEffect, useState } from 'react'
import { NavDrawer } from './NavDrawer'

export const MarketingHeader: FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0)

  const pageHeight = window.innerHeight / 1.8

  const handleScroll = () => {
    setScrollPosition(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <header className='sticky top-0 z-10 flex w-full border-b border-slate-200 bg-white/95 [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
      <div className='mx-auto flex w-full items-center gap-x-4 p-4 md:max-w-5xl md:py-6 lg:justify-between'>
        <div className='lg:hidden'>
          <NavDrawer>
            <NavItem href={route('contribution.index')}>
              <ContributionLogo className='p-0.5' />
              Contributions
            </NavItem>
            <NavItem href={route('emergency-savings.index')}>
              <EmergencySavingsLogo className='p-0.5' />
              Emergency savings
            </NavItem>
            <NavItem href={route('tfsa.index')}>
              <TFSALogo className='p-0.5' />
              Tax-free investments
            </NavItem>
            <div className='mt-auto' />
            <NavItem href={route('learn')}>
              <Icon>local_library</Icon>Learn
            </NavItem>
            <AnchorRouter
              target='_blank'
              href='https://wa.me/27686244900'
              className='relative flex w-full items-center gap-2 truncate rounded-xl p-3 font-normal text-medium hover:bg-nav focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
            >
              <Icon>support</Icon>Contact support
            </AnchorRouter>
            <Separator.Root decorative className='h-px w-full bg-slate-200' />
            <NavItem href='/login'>
              <Button>Login</Button>
            </NavItem>
          </NavDrawer>
        </div>
        <Router href={'/'}>
          <FynbosLogo />
        </Router>
        <div className='ml-10 hidden gap-10 font-medium text-strong lg:flex'>
          <Router href='#pricing'>Pricing</Router>
          <Router href='/learn'>Learn</Router>
        </div>
        <Router
          href='/login'
          className='group ml-auto flex w-full max-w-max items-center rounded-full bg-nav p-1 transition-colors hover:bg-nav-hover'
        >
          <span className='ml-4 mr-4 font-medium leading-none text-medium'>
            Start
          </span>
          <div
            className={cn(
              'flex items-center justify-center rounded-full bg-nav p-2 transition-colors group-hover:bg-green-400 group-hover:text-white',
              scrollPosition > pageHeight && 'bg-green-400 text-white'
            )}
          >
            <Icon className=''>arrow_right_alt</Icon>
          </div>
        </Router>
      </div>
    </header>
  )
}

type NavItemProps = {
  children?: ReactNode
  href: string
  type?: 'doc' | 'section'
}

const NavItem: FC<NavItemProps> = ({ children, href, type }) => {
  return (
    <Router
      href={href}
      className={cn(
        type == 'section' && 'pl-8',
        'relative flex w-full items-center gap-2 truncate rounded-xl p-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus'
      )}
    >
      {children}
    </Router>
  )
}

NavItem.displayName = 'ListItem'
